import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { PageTitle, PolicyTitle, PolicyPara } from '../../styles/pages/policyPage.styles'

const Privacy = () => {
    return(
        <Container fluid>
            <Row className="mx-auto my-5">
                <Col sm="12" md="11">
                    <PageTitle>Privacy and Security</PageTitle>
                    <small>Policy</small>
                </Col>
            </Row>
            <Row sm={1} className="mx-auto mt-3">
                <Col>
                    <p>Our privacy policy is simple: any information you give us stays with us.</p>
                    <p>When you use our Website, we collect and store your personal information, which is provided by you from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience. This allows us to provide services and features that most likely meet your needs, and to customize our Website to make your experience safer and easier. More importantly, while doing so, we collect personal information from you that we consider necessary for achieving this purpose.</p>
                    <p>In general, you can browse the Website without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us.</p>
                </Col>
                <Col>
                    <PolicyTitle>Limited Data Access</PolicyTitle>
                    <PolicyPara>Within our organization, your personal data is accessible to only a limited number of employees with special access privileges. Although we may, from time to time, compile general demographic information based on your order, this information is shared within our organization only and has no identifiable personal data associated with it.</PolicyPara>
                </Col>
                <Col>
                    <PolicyTitle>Information Collected</PolicyTitle>
                    <PolicyPara>To enable you to place an order on our site, we need to have the following basic information about you: First Name, Last Name, Address, City, Zip code, State, Country, Phone Number and Contact E-mail address.
Apart from this, our systems gather certain details about your computer's internet connection like your IP address when you visit our site. Your IP address does not identify you personally. We use this information to deliver our web pages to you upon request, to customize our site as per your interest, to calculate the number of visitors on our site and to know the geographic locations from where our visitors come.
We do not allow any unauthorized person or organization, be it other members, visitors, and anyone not in our organization, to use any information collected from you.</PolicyPara>
                    <PolicyPara>Note: Our privacy policy is subject to change at any time without notice.
By visiting this Website, you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree, please do not use or access our Website.</PolicyPara>
                </Col>
                <Col>
                    <PolicyTitle>Information Modification</PolicyTitle>
                    <PolicyPara>You can modify your personal details by accessing the Edit Profile option at any time from our website.</PolicyPara>
                </Col>
                <Col>
                    <PolicyTitle>Information Usage</PolicyTitle>
                    <PolicyPara>The most important usage of the information collected from you is your email address which is used to inform you that your order has been confirmed/executed. Your email address is also used to inform you for your customer service related queries and for any newsletters sent. All other information collected is confidentially stored and will not be disclosed unless needed as per the requirement of the law authorities or in case of any disputes.</PolicyPara>
                    <PolicyPara>We will occasionally ask you to complete optional online surveys. These surveys may ask you for contact information and demographic information. We use this data to tailor your experience at our Website, providing you with content that we think you might be interested in and to display content according to your preferences.</PolicyPara>
                </Col>
                <Col>
                    <PolicyTitle>Your Consent</PolicyTitle>
                    <PolicyPara>By using the Website and/ or by providing your information, you consent to the collection and use of the information you disclose on the Website in accordance with this Privacy Policy, including but not limited to Your consent for sharing your information as per this privacy policy.</PolicyPara>
                </Col>
            </Row>
        </Container>
    )
}

export default Privacy